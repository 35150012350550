$(window).on('load', function(){
  animate();
});
function animate(){
  window.sr = ScrollReveal({ });
  sr.reveal( '.reveal', { origin: 'bottom' , distance: '20px', duration: 500 , scale: 1.0, delay: 200 ,opacity: 0, easing: "ease"});
  // sr.reveal( '.reveal-about1', { origin: 'bottom' , distance: '20px', duration: 500 , scale: 1.0, delay: 1000 ,opacity: 0, easing: "ease"});

  // // sr.reveal( '', { origin: 'bottom' , distance: '20px', duration: 300 , scale: 1.0, delay: 200 ,opacity: 0, easing: "ease", desktop: false});

  // // front-page
  // // sr.reveal( '.reveal-1-sp', { origin: 'left' , distance: '50px', duration: 1000 , scale: 1.0, delay: 1000, opacity: 0, easing: "ease", desktop: false});
  // // sr.reveal( '.reveal-1', { origin: 'left' , distance: '50px', duration: 1000 , scale: 1.0, delay: 1000, opacity: 0, easing: "ease", mobile: false});
  // // sr.reveal( '.reveal-2', { origin: 'left' , distance: '50px', duration: 1000 , scale: 1.0, delay: 1500, opacity: 0, easing: "ease", mobile: false});
  // sr.reveal( '.reveal-hero-sub', { origin: 'left' , distance: '50px', duration: 1000 , scale: 1.0, delay: 4000, opacity: 0, easing: "ease"});
  // sr.reveal( '.reveal-index-about', { origin: 'bottom' , distance: '50px', duration: 500 , scale: 1.0, delay: 300, opacity: 0, easing: "ease"});

  // // works
  // sr.reveal( '.services-content', { origin: 'bottom' , distance: '20px', duration: 500 , scale: 1.0, delay: 500 ,opacity: 0, easing: "ease"});
};
