$(function() {
  var headerHeight = $('.header').outerHeight();
  var navHeight = $('.j-header-lead').outerHeight();
  var fixedClass = '--fixed';
  
  $(window).on('load scroll', function() {
    var value = $(this).scrollTop()
    
    if (navHeight > headerHeight + value) {
      $('.header').removeClass(fixedClass);
    } else {
      $('.header').addClass(fixedClass);
    }
  });
});
