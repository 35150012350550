// https://github.com/mntn-dev/t.js?files=1
// タイピング風テキストエフェクト

$(function(){
  $('#t-hero').t({
    delay: 0.5, //アニメーションの遅延
    speed: 30, //アニメーションの速度
    speed_vary: false, //リアルなタイピングのスピード
    beep: false, //タイピング音の有無
    mistype: false, //ミスタイプの有無
    locale: 'en', //キーボードレイアウト。'en' (english) もしくは 'de' (german)
    caret: false, //カーソル
    blink: false, //カーソルの点滅の有無
    blink_perm: false, //カーソルの点滅の継続
    repeat: 0, //繰り返し
    tag: 'span', //要素を内包するタグ
    pause_on_click: false, //クリックで一時停止
    // init:function(elm){}, //タイピング開始時のコールバック
    // typing:function(elm,chr_or_elm,left,total){}, //タイピング毎のコールバック
    // fin:function(elm){} //タイピング終了時のコールバック
  });
});

$(function($) {
  const doAnimations = function() {
    const offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
    const textAnimatables = $('[id^=t-about-heading]');
    
    if ($animatables.length == 0) {
      $(window).off('scroll', doAnimations);
    }
    
		$animatables.each(function(i) {
      const $animatable = $(this);
			if (($animatable.offset().top + $animatable.height() - 20) < offset) {
        $animatable.addClass('animated');
			}
    });

    textAnimatables.each(function(element) {
      const $textAnimatable = $(this);
      if ($textAnimatable.hasClass('animated')) {
        return;
      }
      if (($textAnimatable.offset().top + $textAnimatable.height() - 20) < offset) {
        $($textAnimatable).t({
          delay: 0,
          speed: 75,
          caret: false,
          speed_vary: false,
          mistype: false,
          fin:function(){
            $($textAnimatable).siblings('#t-about-message').addClass('animated');
          }
        });
        $($textAnimatable).addClass('animated');
      }
    });
  };

	$(window).on('scroll', doAnimations);
  $(window).trigger('scroll');
});