var iOSviewportW = 0;
var ua = navigator.userAgent.toLowerCase();
var isiOS = (ua.indexOf("iphone") > -1) || (ua.indexOf("ipod") > -1) || (ua.indexOf("ipad") > -1);
var w = window.innerWidth;
if(isiOS){
  iOSviewportW = window.innerWidth;
  w = iOSviewportW;
}

$(function() {
   // #で始まるアンカーをクリックした場合に処理
   $(".menu-item a[href^='#']").click(function() {
     var href= $(this).attr("href").replace( "#" , "" );
     var target = $('.' + href);
     console.log(target);
     var position = target.offset().top - 58;
     if(w < 768){
       $('.menu-trigger').toggleClass('active');
       $('body').toggleClass('noscroll');
       $('.globalMenu').fadeToggle();

       setTimeout(function(){
         $('body,html').animate({scrollTop:position}, 500, 'swing');
       }, 500);

     } else {
       $('body,html').animate({scrollTop:position}, 500, 'swing');
     }
    return false;
   });
});

// $(document).on('click', 'a.kyoudou__button', function(){
//   var href= $(this).attr("href").replace( "#" , "" );
//   var target = $('.' + href);
//   var position = target.offset().top - 58;
//   $('body,html').animate({scrollTop:position}, 500, 'swing');
// })

// $(".globalMenu__item a[href^='#']").click(function() {
//   // var w = window.innerWidth;
//   // if(isiOS){
//   //   iOSviewportW = window.innerWidth;
//   //   w = iOSviewportW;
//   // }
//   var href= $(this).attr("href");
//   var target = $(href == "#" || href == "" ? 'html' : href);
//   var position = target.offset().top - 83;
//
//   if(w < 768){
//     $('.menu-trigger').toggleClass('active');
//     $('body').toggleClass('noscroll');
//     $('.globalMenu').fadeToggle();
//
//     setTimeout(function(){
//       $('body,html').animate({scrollTop:position}, 500, 'swing');
//     }, 1000);
//
//   } else {
//     $('body,html').animate({scrollTop:position}, 500, 'swing');
//   }
//  return false;
// });
